import { compact } from "lodash";
import PersonIcon from "@mui/icons-material/Person";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Scope } from "@/store/permissions";
import CollectionsBookmark from "@mui/icons-material/CollectionsBookmark";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GroupsIcon from "@mui/icons-material/Groups";
import RouteIcon from "@mui/icons-material/Route";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LinkIcon from "@mui/icons-material/Link";

interface MenuItem {
    label: string;
    name?: string;
    children?: MenuItem[];
    icon?: JSX.Element;
    href?: string;
    scopes?: string[];
}

export const installationMenuItems = [
    {
        label: "users",
        name: "users",
        children: compact([
            {
                label: "users",
                href: "/installation/users",
                icon: <PersonIcon />,
                scopes: [Scope.USER_READ],
            },
            {
                label: "roles",
                href: "/installation/roles",
                icon: <AdminPanelSettingsIcon />,
                scopes: [Scope.ROLES_READ],
            },
            {
                label: "groups",
                href: "/installation/groups",
                icon: <GroupsIcon />,
                scopes: [Scope.GROUP_READ],
            },
            {
                label: "general-settings",
                href: "/installation/general-settings",
                icon: <ManageAccountsIcon />,
                scopes: [Scope.GROUP_READ],
            },
        ]),
    },
    {
        label: "products",
        name: "products",
        children: compact([
            {
                label: "products",
                href: "/installation/products",
                icon: <ShoppingCartIcon />,
                scopes: [Scope.PRODUCT_READ],
            },
        ]),
    },
] satisfies MenuItem[];

export const contentMenuItems = [
    {
        label: "app-content",
        name: "educational-programs",
        children: compact([
            {
                label: "educational-programs",
                href: "/content/educational-programs",
                icon: <CollectionsBookmark />,
                scopes: [Scope.EDUCATIONAL_PROGRAMS_READ],
            },
            {
                label: "educational-paths",
                href: "/content/educational-paths",
                icon: <RouteIcon />,
                scopes: [Scope.EDUCATIONAL_PATH_READ],
            },
            {
                label: "modules",
                href: "/content/modules",
                icon: <ViewModuleIcon />,
                scopes: [Scope.MODULES_READ],
            },
            {
                label: "didactic-tools",
                href: "/content/didactic-tools",
                icon: <HistoryEduIcon />,
                scopes: [Scope.EDUCATIONAL_PATH_READ],
            },
            {
                label: "reference-cards",
                href: "/content/reference-cards",
                icon: <ViewCarouselIcon />,
                scopes: [Scope.REFERENCE_CARDS_READ],
            },
            {
                label: "shortcodes",
                href: "/content/shortcodes",
                icon: <LinkIcon />,
                scopes: [Scope.SHORTCODE_READ],
            },
        ]),
    },
] satisfies MenuItem[];
