import { type ReactNode } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import { DRAWER_BREAKPOINT, DRAWER_WIDTH } from "@/lib/general";
import noop from "@/lib/noop";

const Container = ({
    children,
    mobileOpen = false,
    handleDrawerToggle = noop,
}: {
    children: ReactNode;
    mobileOpen: boolean;
    handleDrawerToggle?: () => void;
}) => (
    <Box
        component="nav"
        sx={{
            width: { [DRAWER_BREAKPOINT]: DRAWER_WIDTH },
            flexShrink: { [DRAWER_BREAKPOINT]: 0 },
        }}
    >
        <Drawer
            elevation={0}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: {
                    xs: "block",
                    [DRAWER_BREAKPOINT]: "none",
                },
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: DRAWER_WIDTH,
                },
            }}
        >
            {children}
        </Drawer>
        <Drawer
            elevation={0}
            variant="permanent"
            sx={{
                display: {
                    xs: "none",
                    [DRAWER_BREAKPOINT]: "block",
                },
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: DRAWER_WIDTH,
                },
            }}
            open
        >
            {children}
        </Drawer>
    </Box>
);

export default Container;
