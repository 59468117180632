import { createContext } from "react";
import type Keycloak from "keycloak-js";

export interface KeycloakContextValue {
    client: Keycloak;
    loading?: boolean;
    initialized?: boolean;
}

const KeycloakContext = createContext<KeycloakContextValue | null>(null);

export default KeycloakContext;
