import {
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { type ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTenant from "@/contexts/Tenant/useTenant";

export interface TenantTableProps {
    /**
     * Optional Callback for when a tenant is selected. This can be used to for instance close a dialog that is showing
     * the tenant table.
     */
    onChange?: () => void;
}
3;
export const TenantTable = ({ onChange }: TenantTableProps) => {
    const { t } = useTranslation();
    const { setTenantName, getTenants, removeToken } = useTenant();
    const [searchQuery, setSearchQuery] = useState("");
    const [data, setData] = useState<any>();

    useEffect(() => {
        getTenants()
            .catch((error) => console.error(error))
            .then((res) => setData(res.data));
    }, [getTenants]);

    const handleSetTenant = (tenant: any) => {
        typeof onChange === "function" && onChange();
        removeToken();
        setTenantName(tenant.name);
    };

    return (
        <div>
            <Box sx={{ p: 2 }}>
                <FormControl fullWidth>
                    <TextField
                        size="small"
                        label={t("tenant.search.label")}
                        data-test-id="user-lastName"
                        value={searchQuery}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setSearchQuery(e.target.value)
                        }
                        inputProps={{
                            "data-testid": "tenant-search",
                        }}
                    />
                </FormControl>
            </Box>
            <TableContainer>
                <Table size="small" data-testid="tenants-table">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <strong>{t("tenant.name")}</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.getTenants
                            .filter((item: any) =>
                                item.name
                                    .toLocaleLowerCase()
                                    .includes(searchQuery.toLocaleLowerCase()),
                            )
                            .map((item: any, index: number) => (
                                <TableRow hover key={index}>
                                    <TableCell
                                        tabIndex={-1}
                                        onClick={() => handleSetTenant(item)}
                                    >
                                        {item.name}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TenantTable;
