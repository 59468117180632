import { type ReactNode, useState } from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import { DRAWER_BREAKPOINT, DRAWER_WIDTH } from "@/lib/general";
import {
    installationMenuItems,
    contentMenuItems,
} from "@/lib/installationMenuItems";
import MainDrawer from "@/components/organisms/MainDrawer/MainDrawer";
import MainAppBar from "@/components/organisms/MainAppBar/MainAppBar";
import { useCmsMode } from "@/hooks/useCmsMode";

export default function ResponsiveDrawer({
    children,
}: {
    children?: ReactNode;
}) {
    const cmsMode = useCmsMode();

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Stack
            sx={{
                flexDirection: {
                    [DRAWER_BREAKPOINT]: "row",
                },
            }}
        >
            <MainAppBar handleDrawerToggle={handleDrawerToggle} />
            <MainDrawer
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                menuItems={
                    cmsMode === "INSTALLATION"
                        ? installationMenuItems
                        : contentMenuItems
                }
            />
            <Box
                component="main"
                sx={{
                    minHeight: "100vh",
                    width: {
                        [DRAWER_BREAKPOINT]: `calc(100% - ${DRAWER_WIDTH}px)`,
                    },
                }}
            >
                <Toolbar
                    sx={{
                        display: ["none", "none", "block"],
                    }}
                />
                <Box position="relative" minHeight="calc(100% - 64px)">
                    {children || <Outlet />}
                </Box>
            </Box>
        </Stack>
    );
}

export { ResponsiveDrawer };
