import type { StateCreator } from "zustand";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { devtools } from "zustand/middleware";
import * as scopes from "./permissions.scopes";

export const Scope = { ...scopes } as const;

export type Scope = (typeof Scope)[keyof typeof Scope];

interface PermissionsStoreProps {
    ready: boolean;
    scopes: Scope[];
}

interface PermissionsStoreState extends PermissionsStoreProps {
    setReady: (ready: boolean) => void;
    hasPermission: (scope: Scope | Scope[]) => boolean;
    setScopes: (scopes: Scope[]) => void;
}

const createPermissionsStore: StateCreator<PermissionsStoreState> = (
    set,
    get,
) => ({
    ready: false,
    setReady: (ready) => set({ ready }),
    scopes: [],
    setScopes: (scopes) => set({ scopes }),
    hasPermission: (scope) => {
        const { scopes } = get();
        if (Array.isArray(scope)) {
            return scope.every((s) => scopes.includes(s));
        }
        return scopes.includes(scope);
    },
});

export const usePermissionsStore = create<PermissionsStoreState>()(
    devtools(
        persist(createPermissionsStore, {
            name: "witty/permissions",
            storage: createJSONStorage(() => sessionStorage),
            partialize: (state) => ({
                scopes: state.scopes,
            }),
        }),
    ),
);
