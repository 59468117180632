/* eslint-disable sonarjs/no-duplicate-string */
import { type RouteObject, Navigate, createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "./middleware/ProtectedRoute";
import { ResponsiveDrawer } from "@/components/templates/Layout/MainLayout";
import NamespaceValidator from "./middleware/TenantValidator/TenantValidator";
import Error from "@/components/pages/Error";
/**
 * Routes that relate to the Installation-part of the CMS
 */
const installationRoutes: RouteObject[] = [
    {
        path: "/installation",
        children: [
            {
                index: true,
                element: <Navigate to="users" replace={true} />,
            },
            {
                path: "users",
                id: "users",
                handle: {
                    crumb: () => "users",
                },
                children: [
                    {
                        index: true,
                        id: "users-list",
                        lazy: () => import("@/components/pages/users/ListAppUsers"),
                    },
                    {
                        path: "create",
                        id: "users-create",
                        handle: {
                            crumb: () => "users-create",
                        },
                        lazy: () => import("@/components/pages/users/CreateAppUser"),
                    },
                    {
                        path: ":id",
                        children: [
                            {
                                index: true,
                                element: <Navigate to="general" replace={true} />,
                            },
                            {
                                path: "general",
                                id: "users-edit",
                                handle: {
                                    crumb: (name: string) => `${name}`,
                                    noTranslate: true,
                                },
                                lazy: () => import("@/components/pages/users/EditAppUser"),
                            },
                            {
                                path: "licenses",
                                id: "users-licenses",
                                handle: {
                                    crumb: (name: string) => `${name}`,
                                    noTranslate: true,
                                },
                                lazy: () => import("@/components/pages/users/LicensesForAppUser"),
                            },
                        ],
                    },
                ],
            },
            {
                path: "general-settings",
                id: "general-settings",
                handle: {
                    crumb: () => "general-settings",
                },
                children: [
                    {
                        index: true,
                        id: "general-user-settings",
                        lazy: () => import("@/components/pages/General/GeneralUserSettings"),
                    },
                ],
            },
            {
                path: "roles",
                id: "roles",
                handle: {
                    crumb: () => "roles",
                },
                children: [
                    {
                        index: true,
                        id: "roles-list",
                        lazy: () => import("@/components/pages/roles/ListRoles"),
                    },
                    {
                        path: "create",
                        id: "roles-create",
                        handle: {
                            crumb: () => "roles-create",
                        },
                        lazy: () => import("@/components/pages/roles/CreateRole"),
                    },
                    {
                        path: ":id",
                        element: <Navigate to="edit" replace={true} />,
                    },
                    {
                        path: ":id/edit",
                        id: "roles-edit",
                        handle: {
                            crumb: () => "roles-edit",
                        },
                        lazy: () => import("@/components/pages/roles/EditRole"),
                    },
                ],
            },
            {
                path: "products",
                id: "products",
                handle: {
                    crumb: () => "products",
                },
                children: [
                    {
                        index: true,
                        id: "products-list",
                        lazy: () => import("@/components/pages/Products/ListProducts"),
                    },
                    {
                        path: "create",
                        id: "products-create",
                        handle: {
                            crumb: () => "products-create",
                        },
                        lazy: () => import("@/components/pages/Products/CreateProducts"),
                    },
                    {
                        path: ":id",
                        children: [
                            {
                                index: true,
                                element: <Navigate to="edit" replace={true} />,
                            },
                            {
                                path: "edit",
                                id: "products-edit",
                                handle: {
                                    crumb: () => "products",
                                },
                                lazy: () => import("@/components/pages/Products/EditProduct"),
                            },
                        ],
                    },
                ],
            },
            {
                path: "groups",
                id: "groups",
                lazy: () => import("@/components/pages/groups/Groups"),
                handle: {
                    crumb: () => "groups",
                },
                children: [
                    {
                        path: "create",
                        id: "groups-create",
                        handle: {
                            crumb: () => "groups-create",
                        },
                        lazy: () => import("@/components/pages/groups/CreateGroup"),
                    },
                    {
                        path: ":id",
                        children: [
                            {
                                index: true,
                                element: <Navigate to="edit" replace={true} />,
                            },
                            {
                                path: "edit",
                                id: "groups-edit",
                                handle: {
                                    crumb: () => "groups-edit",
                                },
                                lazy: () => import("@/components/pages/groups/EditGroup"),
                            },
                            {
                                path: "users",
                                id: "groups-users",
                                handle: {
                                    crumb: () => "groups-users",
                                },
                                lazy: () => import("@/components/pages/groups/EditGroupUsers"),
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

/**
 * Routes that relate to the Content-part of the CMS
 */
const contentRoutes: RouteObject[] = [
    {
        path: "/content",
        children: [
            {
                index: true,
                element: <Navigate to="educational-programs" replace={true} />,
            },
            {
                path: "educational-programs",
                handle: {
                    crumb: () => "educational-programs",
                },
                children: [
                    {
                        index: true,
                        id: "educational-programs-list",
                        lazy: () => import("@/components/pages/EducationalPrograms/ListEducationalPrograms"),
                    },
                    {
                        path: "create",
                        handle: {
                            crumb: () => "educational-programs-create",
                        },
                        lazy: () => import("@/components/pages/EducationalPrograms/CreateEducationalProgram"),
                    },
                    {
                        path: ":id",
                        handle: {
                            crumb: (name: string) => `${name}`,
                            noTranslate: true,
                        },
                        children: [
                            {
                                index: true,
                                element: <Navigate to="general" replace={true} />,
                            },
                            {
                                path: "general",
                                lazy: () => import("@/components/pages/EducationalPrograms/EditEducationalProgram"),
                                handle: {
                                    crumb: () => "educational-programs-general",
                                },
                            },
                            {
                                path: "educational-paths",
                                lazy: () =>
                                    import(
                                        "@/components/pages/EducationalPrograms/EditEducationalProgramsListEducationalPaths"
                                    ),
                                handle: {
                                    crumb: () => "educational-programs-educational-paths",
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: "educational-paths",
                handle: {
                    crumb: () => "educational-paths",
                },
                children: [
                    {
                        index: true,
                        id: "educational-paths-list",
                        lazy: () => import("@/components/pages/educationalPaths/ListEducationalPath"),
                    },
                    {
                        path: "create",
                        id: "educational-paths-create",
                        lazy: () => import("@/components/pages/educationalPaths/CreateEducationalPath"),
                    },
                    {
                        path: ":id",
                        id: "educational-path",
                        handle: {
                            crumb: (name: string) => `${name}`,
                            noTranslate: true,
                        },
                        children: [
                            {
                                index: true,
                                element: <Navigate to="general" replace={true} />,
                            },
                            {
                                path: "general",
                                lazy: () => import("@/components/pages/educationalPaths/EditEducationalPath"),
                                handle: {
                                    crumb: () => "educational-paths-general",
                                },
                            },
                            {
                                path: "modules",
                                lazy: () =>
                                    import("@/components/pages/educationalPaths/EditEducationalProgramsListModules"),
                                handle: {
                                    crumb: () => "educational-paths-modules",
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: "modules",
                id: "modules",
                handle: {
                    crumb: () => "modules",
                },
                children: [
                    {
                        index: true,
                        id: "modules-list",
                        lazy: () => import("@/components/pages/Modules/ListModules"),
                    },
                    {
                        path: "create",
                        id: "modules-create",
                        lazy: () => import("@/components/pages/Modules/CreateModule"),
                    },
                    {
                        path: ":id",
                        element: <Navigate to="general" replace={true} />,
                    },
                    {
                        path: ":id",
                        id: "module",
                        handle: {
                            crumb: (name: string) => `${name}`,
                            noTranslate: true,
                        },
                        children: [
                            {
                                index: true,
                                element: <Navigate to="general" replace={true} />,
                            },
                            {
                                path: "general",
                                lazy: () => import("@/components/pages/Modules/EditModule"),
                                handle: {
                                    crumb: () => "modules-general",
                                },
                            },
                            {
                                path: "didactic-tools",
                                lazy: () => import("@/components/pages/Modules/EditModuleListDidacticTools"),
                                handle: {
                                    crumb: () => "modules-didactic-tools",
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: "didactic-tools",
                id: "didactic-tools",
                handle: {
                    crumb: () => "didactic-tools",
                },
                children: [
                    {
                        index: true,
                        id: "didactic-tools-list",
                        lazy: () => import("@/components/pages/DidacticTools/ListDidacticTools"),
                    },
                    {
                        path: "create",
                        id: "didactic-tools-create",
                        lazy: () => import("@/components/pages/DidacticTools/CreateDidacticTool"),
                    },
                    {
                        path: ":id",
                        id: "didactic-tool",
                        handle: {
                            crumb: (name: string) => `${name}`,
                            noTranslate: true,
                        },
                        children: [
                            {
                                index: true,
                                element: <Navigate to="general" replace={true} />,
                            },
                            {
                                path: "general",
                                lazy: () => import("@/components/pages/DidacticTools/EditDidacticTool"),
                                handle: {
                                    crumb: () => "didactic-tools-edit",
                                },
                            },
                            {
                                path: "building-blocks",
                                lazy: () => import("@/components/pages/DidacticTools/EditDidacticToolBuildingBlocks"),
                                handle: {
                                    crumb: () => "didactic-tools-building-blocks",
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: "reference-cards",
                id: "reference-cards",
                handle: {
                    crumb: () => "reference-cards",
                },
                children: [
                    {
                        index: true,
                        id: "reference-cards-list",
                        lazy: () => import("@/components/pages/ReferenceCards/ListReferenceCards"),
                    },
                    {
                        path: "create",
                        id: "reference-cards-create",
                        lazy: () => import("@/components/pages/ReferenceCards/CreateReferenceCard"),
                    },
                    {
                        path: ":id",
                        id: "reference-card",
                        handle: {
                            crumb: (name: string) => `${name}`,
                            noTranslate: true,
                        },
                        children: [
                            {
                                index: true,
                                element: <Navigate to="general" replace={true} />,
                            },
                            {
                                path: "general",
                                lazy: () => import("@/components/pages/ReferenceCards/EditReferenceCard"),
                                handle: {
                                    crumb: () => "reference-cards-edit",
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: "shortcodes",
                id: "shortcodes",
                handle: {
                    crumb: () => "shortcodes",
                },
                children: [
                    {
                        index: true,
                        id: "shortcodes-list",
                        lazy: () => import("@/components/pages/Shortcodes/ListShortcodes"),
                    },
                    {
                        path: "create",
                        id: "shortcodes-create",
                        lazy: () => import("@/components/pages/Shortcodes/CreateShortcode"),
                    },
                    {
                        path: ":id",
                        id: "shortcode",
                        children: [
                            {
                                index: true,
                                element: <Navigate to="edit" replace={true} />,
                            },
                            {
                                path: "edit",
                                lazy: () => import("@/components/pages/Shortcodes/EditShortcode"),
                                handle: {
                                    crumb: () => "shortcodes-edit",
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

/**
 * Note that the id property is used to generate the breadcrumb label through
 * translations.
 */
export const routes: RouteObject[] = [
    {
        path: "/",
        id: "root",
        element: (
            <ProtectedRoute>
                <NamespaceValidator>
                    <ResponsiveDrawer />
                </NamespaceValidator>
            </ProtectedRoute>
        ),
        children: [
            {
                path: "/",
                element: <Navigate to="/installation" replace={true} />,
            },
            {
                path: "/account",
                children: [
                    {
                        id: "account",
                        path: "/account",
                        element: <Navigate to="/account/personal-information" replace={true} />,
                    },
                    {
                        id: "account-personal-information",
                        path: "/account/personal-information",
                        lazy: () => import("@/components/pages/PersonalInformation"),
                    },
                ],
            },

            ...installationRoutes,
            ...contentRoutes,
        ],
    },

    {
        path: "*",
        element: <Error />,
    },
];

export default createBrowserRouter(routes);
