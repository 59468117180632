import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { useTranslation } from "react-i18next";
import TenantTable from "../TenantTable/TenantTable";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { alpha } from "@mui/material/styles";
import useTenant from "@/contexts/Tenant/useTenant";

const TenantSelect = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const { clearTenantName, removeToken, tenantName } = useTenant();

    function handleClearTenantName() {
        clearTenantName();
        removeToken();
        setOpen(false);
    }

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
                <DialogTitle>{t("tenant.choose")}</DialogTitle>

                <TenantTable onChange={() => setOpen(false)} />

                <DialogActions>
                    <Button
                        onClick={handleClearTenantName}
                        data-testid="deselect-tenant"
                    >
                        {t("tenant.deselect")}
                    </Button>
                    <Button onClick={() => setOpen(false)}>
                        {t("cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
            <FormControl
                data-testid="tenant-select"
                sx={(theme) => ({
                    minWidth: 200,
                    borderColor: theme.palette.primary.contrastText,
                    ".MuiOutlinedInput-notchedOutline": {
                        borderColor: alpha(
                            theme.palette.primary.contrastText,
                            0.5,
                        ),
                    },
                    ".MuiSvgIcon-root": {
                        fill: theme.palette.primary.contrastText,
                    },
                })}
            >
                <InputLabel
                    htmlFor="my-input"
                    shrink={Boolean(tenantName)}
                    sx={(theme) => ({
                        color: theme.palette.primary.contrastText,
                    })}
                >
                    {t("tenant.select")}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tenantName}
                    label={t("tenant.select")}
                    inputProps={{}}
                    sx={(theme) => ({
                        color: theme.palette.primary.contrastText,
                    })}
                >
                    <MenuItem value={tenantName || ""}>{tenantName}</MenuItem>
                </Select>
                <Box
                    sx={{
                        background: "rgb(255 0 0 / 0%)",
                        position: "absolute",
                        inset: 0,
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpen((state) => !state);
                        return false;
                    }}
                />
            </FormControl>
        </>
    );
};

export default TenantSelect;
