import { type ReactNode } from "react";
import { useKeycloak } from "@/contexts/Keycloak";

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
    const { initialized, loading, client } = useKeycloak();

    if (loading || !initialized) return null;

    if (client.authenticated) return <>{children}</>;

    void client.login();

    return null;
};
