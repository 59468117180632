import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n.config.ts";
import keycloak from "@bespeak/keycloak/keycloak";
import { KeycloakProvider } from "@/contexts/Keycloak";
import AuthorizationProvider from "@/contexts/Authorization";
import TenantProvider from "@/contexts/Tenant";
import GraphQLProvider from "@/contexts/GraphQL";
import App from "./App";
import RefreshingFetchProvider from "@/contexts/RefreshingFetch";

void (async function main() {
    const root = ReactDOM.createRoot(
        document.getElementById("root") as HTMLElement,
    );

    const keycloakInstance = keycloak({
        mock: import.meta.env.MODE === "e2e",
        url: import.meta.env.VITE_KEYCLOAK_URL,
        realm: import.meta.env.VITE_CMS_KEYCLOAK_REALM,
        clientId: import.meta.env.VITE_CMS_KEYCLOAK_CLIENT,
    });

    await keycloakInstance.init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    });

    root.render(
        <KeycloakProvider client={keycloakInstance}>
            <React.StrictMode>
                <AuthorizationProvider>
                    <TenantProvider>
                        <RefreshingFetchProvider>
                            <GraphQLProvider>
                                <App />
                            </GraphQLProvider>
                        </RefreshingFetchProvider>
                    </TenantProvider>
                </AuthorizationProvider>
            </React.StrictMode>
        </KeycloakProvider>,
    );
})();
