import { useTranslation } from "react-i18next";
import { isString } from "lodash";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import ListSubheader from "@mui/material/ListSubheader";
import type { ItemsProps } from "./ItemsProps";
import { usePermissionsStore, type Scope } from "@/store/permissions";

const Items = ({ menuItems = [] }: ItemsProps) => {
    const ready = usePermissionsStore((state) => state.ready);
    const hasPermission = usePermissionsStore((state) => state.hasPermission);

    const { t } = useTranslation("common", {
        keyPrefix: "menu-items",
    });

    const tHeader = (categoryName: string) => {
        return t(`${categoryName}.label`);
    };

    const tItem = (categoryName: string, itemName: string) => {
        return t(`${categoryName}.items.${itemName}`);
    };

    const { pathname } = useLocation();

    const isSelected = (path: string) => {
        return Boolean(matchPath({ path, end: false }, pathname));
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Toolbar />
            {menuItems
                .filter(
                    (item) =>
                        ready &&
                        item.children
                            .flatMap((child) =>
                                Array.isArray(child.scopes)
                                    ? child.scopes
                                    : [child.scopes ?? ""],
                            )
                            .filter(Boolean)
                            .filter((scope): scope is Scope => isString(scope))
                            .some((scope) => hasPermission(scope)),
                )
                .map((item, index, array) => (
                    <div key={index}>
                        <List
                            aria-labelledby={`list-subheader-${item.name}}`}
                            subheader={
                                <ListSubheader
                                    component="div"
                                    id={`list-subheader-${item.name}}`}
                                >
                                    {tHeader(item.label)}
                                </ListSubheader>
                            }
                        >
                            {item.children
                                .filter((item) =>
                                    hasPermission(item.scopes ?? []),
                                )
                                .map((child, index) => (
                                    <div key={index}>
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                component={NavLink}
                                                selected={isSelected(
                                                    child.href,
                                                )}
                                                to={child.href}
                                            >
                                                <ListItemIcon>
                                                    {child.icon}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={tItem(
                                                        item.label,
                                                        child.label,
                                                    )}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </div>
                                ))}
                        </List>
                        {index < array.length - 1 && <Divider />}
                    </div>
                ))}
        </Box>
    );
};

export default Items;
