import { type ComponentProps } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Items from "./Items/Items";
import Container from "./Container/Container";
import noop from "@/lib/noop";

type ContainerProps = ComponentProps<typeof Container>;
type ItemsProps = ComponentProps<typeof Items>;
type MainDrawerProps = Omit<ContainerProps & ItemsProps, "children">;

const MainDrawer = ({
    menuItems,
    mobileOpen = false,
    handleDrawerToggle = noop,
}: MainDrawerProps) => (
    <Box>
        <Toolbar />
        <Container
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
        >
            <Items menuItems={menuItems} />
        </Container>
    </Box>
);

export default MainDrawer;
