import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

const Error = () => {
    const { t } = useTranslation();

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ position: "absolute", inset: 0 }}
        >
            <Paper elevation={0}>
                <Box px={8} py={4}>
                    <Stack spacing={2}>
                        <Typography variant="h1">{t("error.title")}</Typography>
                    </Stack>
                </Box>
            </Paper>
        </Stack>
    );
};

export default Error;
