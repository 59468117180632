import React from "react";
import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCmsMode } from "@/hooks/useCmsMode";
import { useNavigate } from "react-router-dom";

const ModeToggle = () => {
    const { t } = useTranslation();
    const mode = useCmsMode();
    const navigate = useNavigate();

    const handleSwitch = (
        _: React.MouseEvent<HTMLElement>,
        newMode: string | null,
    ) => {
        if (newMode === null) return;

        if (newMode === "INSTALLATION") {
            navigate("/installation");
        } else {
            navigate("/content");
        }
    };

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        "& .MuiToggleButtonGroup-grouped": {
            borderColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.contrastText,
            "&.Mui-selected": {
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.primary.main,

                "&:hover": {
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.primary.main,
                    cursor: "default",
                },
            },
        },
    }));

    return (
        <StyledToggleButtonGroup value={mode} exclusive onChange={handleSwitch}>
            <ToggleButton
                value="INSTALLATION"
                aria-label={t("mode.installation")}
            >
                {t("mode.installation")}
            </ToggleButton>
            <ToggleButton value="CONTENT" aria-label={t("mode.content")}>
                {t("mode.content")}
            </ToggleButton>
        </StyledToggleButtonGroup>
    );
};

export default ModeToggle;
