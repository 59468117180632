function formatFullName(
    givenName: unknown,
    familyName: unknown,
    ...rest: unknown[]
): string {
    return [givenName, familyName, ...rest]
        .filter((str): str is string => typeof str === "string")
        .map((str) => str.trim())
        .filter((str) => str.length > 0)
        .join(" ");
}

export default formatFullName;
