import type { ReactNode } from "react";
import type Keycloak from "keycloak-js";
import type { KeycloakContextValue } from "./KeycloakContext";
import KeycloakContext from "./KeycloakContext";

export interface KeycloakProviderProps {
    children: ReactNode;
    client: Keycloak;
}

function KeycloakProvider(props: KeycloakProviderProps) {
    const value = {
        loading: false,
        initialized: true,
        client: props.client,
    } satisfies KeycloakContextValue;

    return (
        <KeycloakContext.Provider value={value}>
            {props.children}
        </KeycloakContext.Provider>
    );
}

export default KeycloakProvider;
