import type { ReactNode } from "react";
import { useRef } from "react";
import RefreshingFetchContext from "./RefreshingFetchContext";
import { useKeycloak } from "@/contexts/Keycloak";
import { useTenant } from "@/contexts/Tenant";

interface RefreshingFetchProviderProps {
    children: ReactNode;
}

function RefreshingFetchProvider(props: RefreshingFetchProviderProps) {
    const preFetch = useRef<Promise<any>>();

    const keycloak = useKeycloak();

    const tenant = useTenant();

    const updateKeycloakToken = async () => {
        try {
            await keycloak.client.updateToken(5);
            return keycloak.client.token;
        } catch (error) {
            console.error(error);
            await keycloak.client.login();
        }
    };

    const updateTenantToken = async () => {
        try {
            return await tenant.refreshToken(5);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const updateTokens = async () => {
        let keycloak: string | undefined, tenant: string | undefined;

        try {
            keycloak = await updateKeycloakToken();
            tenant = await updateTenantToken();
        } catch (error) {
            console.error(error);
            throw error;
        }

        return { keycloak, tenant };
    };

    const refreshingFetch: typeof fetch = async (uri, options) => {
        preFetch.current = preFetch.current || updateTokens();

        const tokens = await preFetch.current;

        preFetch.current = void 0;

        const _options: RequestInit = {
            ...options,
            headers: {
                ...options?.headers,
                ...(tokens.keycloak && {
                    Authorization: `Bearer ${tokens.keycloak}`,
                }),
                ...(tokens.tenant && {
                    TenantToken: tokens.tenant,
                }),
            },
        };

        return fetch(uri, _options);
    };

    return (
        <RefreshingFetchContext.Provider value={{ refreshingFetch }}>
            {props.children}
        </RefreshingFetchContext.Provider>
    );
}

export default RefreshingFetchProvider;
