import formatFullName from "./formatFullName";

function avatarUrl(
    firstName: string,
    lastName: string,
    ...rest: string[]
): string {
    const url = new URL("https://ui-avatars.com/api/");
    url.searchParams.set("name", formatFullName(firstName, lastName, ...rest));
    return url.toString();
}

export default avatarUrl;
