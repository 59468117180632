import Avatar from "@/components/atoms/Avatar/Avatar";
import { useQuery } from "@apollo/client";
import { GetAuthenticatedUserDocument } from "@bespeak/apollo";

const CurrentUser = () => {
    const { data: { getAuthenticatedUser } = {} } = useQuery(
        GetAuthenticatedUserDocument,
    );

    return (
        <Avatar
            givenName={getAuthenticatedUser?.firstName}
            familyName={getAuthenticatedUser?.lastName}
        />
    );
};

export default CurrentUser;
