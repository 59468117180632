import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import router from "./router";
import "@fontsource-variable/roboto-flex/full.css";
import "@fontsource-variable/recursive";
import "./styles/general.scss";
import SnackbarProvider from "./components/organisms/Snackbar/Snackbar";
import { DialogProvider } from "./contexts/Dialog/Dialog.provider";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import useTenantName from "./contexts/Tenant/useTenantName";
import beautylevel from "@bespeak/app/public/styles/whitelabels/beautylevel.css?raw";
import makemewitty from "@bespeak/app/public/styles/whitelabels/makemewitty.css?raw";

function App() {
    const [tenantName] = useTenantName();
    const found = document.getElementById("app-theme") as HTMLStyleElement;
    const styleElement =
        found || (document.createElement("style") as HTMLStyleElement);

    styleElement.id = "app-theme";
    if (tenantName === "Beauty Level") {
        styleElement.textContent = beautylevel;
    } else if (tenantName === "Make me witty") {
        styleElement.textContent = makemewitty;
    }

    if (![...document.head.children].includes(styleElement))
        document.head.appendChild(styleElement);

    return (
        <ThemeProvider theme={theme}>
            <DialogProvider>
                <CssBaseline />
                <SnackbarProvider>
                    <Suspense>
                        <RouterProvider router={router} />
                    </Suspense>
                </SnackbarProvider>
            </DialogProvider>
        </ThemeProvider>
    );
}

export default App;
