import type { ReactNode } from "react";
import { useState } from "react";
import { memo } from "react";
import type { AuthorizationContextValue } from "./AuthorizationContext";
import AuthorizationContext from "./AuthorizationContext";
import { useGetAuthenticatedUserAuthoritiesQuery } from "@bespeak/apollo";
import { usePermissionsStore } from "@/store/permissions";
import useAuthenticatedClient from "@/lib/apollo/useAuthenticatedClient";
import { useKeycloak } from "@/contexts/Keycloak";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

export interface AuthorizationProviderProps {
    children: ReactNode;
    value?: AuthorizationContextValue;
}

function AuthorizationProvider(props: AuthorizationProviderProps) {
    const { t } = useTranslation();

    const [AuthoritiesError, setAuthoritiesError] = useState<Error>();

    const { client: keycloakClient } = useKeycloak();

    const setReady = usePermissionsStore((state) => state.setReady),
        setScopes = usePermissionsStore((state) => state.setScopes);

    const [client] = useAuthenticatedClient();

    const { loading } = useGetAuthenticatedUserAuthoritiesQuery({
        skip: !keycloakClient.authenticated,
        client,
        onCompleted: (data) => {
            const authorities = data.getAuthoritiesForAuthenticatedUser;
            setReady(true);
            setScopes(authorities as any);
        },
        onError: (error) => {
            setAuthoritiesError(error);
        },
    });

    const value = { ...props.value, loading };

    return (
        <AuthorizationContext.Provider value={value}>
            {AuthoritiesError && (
                <Snackbar color="error" open={true}>
                    <>
                        <Alert severity="error">{t("error.network")}</Alert>
                        <kbd>{AuthoritiesError?.message}</kbd>
                    </>
                </Snackbar>
            )}
            {props.children}
        </AuthorizationContext.Provider>
    );
}

export default memo(AuthorizationProvider);
