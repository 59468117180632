import Keycloak from "keycloak-js";

export default ({ mock = false, url = "", realm = "", clientId = "" }) => {
    let keycloak: Keycloak;

    if (mock)
        return {
            authenticated: true,
            hasRealmRole() {
                return true;
            },
            async init() {
                return true;
            },
            async login() {
                return void 0;
            },
            async logout() {
                return void 0;
            },
            async updateToken() {
                return true;
            },
        } as unknown as Keycloak; // TODO: should be `satisfies Keycloak` but it's not working

    if (![url, realm, clientId].every(Boolean))
        throw new Error("Please configure keycloak in environment variables.");

    return new Keycloak({ url, realm, clientId });
};
