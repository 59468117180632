import type { Button } from "@mui/material";
import { type ComponentProps, useCallback, useState } from "react";

export type DialogType = {
    actions: ComponentProps<typeof Button>[][];
    title: React.ReactNode;
    children: React.ReactNode;
};

export type DialogResponseType = {
    close: () => void;
};

export const useDialogHook = () => {
    const [dialogs, setDialogs] = useState<DialogType[]>([]);

    const create = useCallback(
        (dialog: DialogType) => {
            setDialogs((dialogs) => [...dialogs, dialog]);

            const index = dialogs.length;

            const response: DialogResponseType = {
                close: () => close(index),
            };

            return response;
        },
        [dialogs],
    );

    const close = (index: number) => {
        setDialogs((dialogs) => {
            const _dialogs = [...dialogs];
            _dialogs.splice(index, 1);
            return _dialogs;
        });
    };

    return { dialogs, create, close };
};
