import type { ReactNode } from "react";
import { useState } from "react";
import type { GraphQLContextValue } from "./GraphQLContext";
import GraphQLContext from "./GraphQLContext";
import {
    ApolloClient,
    ApolloProvider,
    from,
    HttpLink,
    InMemoryCache,
} from "@apollo/client";
import { introspectionResult } from "@bespeak/apollo";
import type { NetworkError } from "@apollo/client/errors";
import { onError } from "@apollo/client/link/error";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import useRefreshingFetch from "@/contexts/RefreshingFetch/useRefreshingFetch";

interface GraphQLProviderProps {
    children: ReactNode;
    value?: GraphQLContextValue;
}

function GraphQLProvider(props: GraphQLProviderProps) {
    const { t } = useTranslation();

    const [NetworkError, setNetworkError] = useState<NetworkError | null>(null);

    const { refreshingFetch } = useRefreshingFetch();

    const value = { ...props.value };

    const errorLink = onError(({ graphQLErrors, response, networkError }) => {
        if (graphQLErrors || response?.data) {
            setNetworkError(null);
        } else if (networkError) {
            setNetworkError(networkError);
        }
    });

    const httpLink = new HttpLink({
        uri: import.meta.env.VITE_API,
        fetch: refreshingFetch,
    });

    const client = new ApolloClient({
        cache: new InMemoryCache({
            possibleTypes: introspectionResult.possibleTypes,
        }),
        link: from([errorLink, httpLink]),
    });

    return (
        <GraphQLContext.Provider value={value}>
            {NetworkError && (
                <Snackbar color="error" open={true}>
                    <>
                        <Alert severity="error">{t("error.network")}</Alert>
                        <kbd>{NetworkError?.message}</kbd>
                    </>
                </Snackbar>
            )}
            <ApolloProvider client={client}>{props.children}</ApolloProvider>
        </GraphQLContext.Provider>
    );
}

export default GraphQLProvider;
