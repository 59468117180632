import { useLocation } from "react-router-dom";

export type CmsMode = "INSTALLATION" | "CONTENT";

/**
 * The CMS has two 'modes', one for configuring an installation and one for managing content. Based on the base path
 * of the current location, we can determine which mode the CMS is currently in.
 *
 * @return the current mode of the CMS, either `"CONTENT"` or `"INSTALLATION"`.
 */
export function useCmsMode(): CmsMode {
    const location = useLocation();
    return location.pathname.startsWith("/content")
        ? "CONTENT"
        : "INSTALLATION";
}
