import { memo, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TenantTable from "@/components/organisms/TenantTable/TenantTable";
import ResponsiveDrawer from "@/components/templates/Layout/MainLayout";
import { useTenant } from "@/contexts/Tenant";

const TenantValidator = ({ children }: { children: ReactNode }) => {
    const { token } = useTenant();
    const { t } = useTranslation();

    return token ? (
        <>{children}</>
    ) : (
        <ResponsiveDrawer>
            <Container>
                <Grid maxWidth={800} sx={{ m: "auto" }}>
                    <Paper sx={{ pb: 2 }}>
                        <Box
                            bgcolor={"lightBackground.main"}
                            sx={{ p: 2 }}
                            component="form"
                            autoComplete="off"
                        >
                            <Alert>{t("tenant.choose")}</Alert>
                        </Box>
                        <TenantTable />
                    </Paper>
                </Grid>
            </Container>
        </ResponsiveDrawer>
    );
};

export default memo(TenantValidator);
