import { type ComponentProps } from "react";
import { styled } from "@mui/material";
import {
    MaterialDesignContent,
    SnackbarProvider as NotistackProvider,
} from "notistack";
import { theme } from "@/styles/theme";

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(
    () => ({
        "&.notistack-MuiContent-success": {
            backgroundColor: theme.palette.success.main,
        },
        "&.notistack-MuiContent-error": {
            backgroundColor: theme.palette.error.main,
        },
        "&.notistack-MuiContent-info": {
            backgroundColor: theme.palette.info.main,
        },
        "&.notistack-MuiContent-warning": {
            backgroundColor: theme.palette.warning.main,
        },
        "&.notistack-MuiContent-default": {
            backgroundColor: theme.palette.grey[800],
        },
    }),
);

type SnackbarProviderProps = ComponentProps<typeof NotistackProvider>;

function SnackbarProvider(props: SnackbarProviderProps) {
    return (
        <NotistackProvider
            SnackbarProps={{
                role: "presentation",
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            Components={{
                default: StyledMaterialDesignContent,
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent,
                info: StyledMaterialDesignContent,
            }}
            {...props}
        />
    );
}

export default SnackbarProvider;
