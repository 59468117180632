import { useMemo } from "react";
import useKeycloak from "@/contexts/Keycloak/useKeycloak";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

/**
 * Provides an ApolloClient instance that is authenticated with the current user's Keycloak token.
 */
function useAuthenticatedClient() {
    const {
        client: { token },
    } = useKeycloak();

    const link = useMemo(() => {
        return new HttpLink({
            uri: import.meta.env.VITE_API,
            fetch: (uri, options) =>
                fetch(uri, {
                    ...options,
                    headers: {
                        ...options?.headers,
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });
    }, [token]);

    const client = useMemo(() => {
        return new ApolloClient({
            cache: new InMemoryCache(),
            link,
        });
    }, [link]);

    return [client] as const;
}

export default useAuthenticatedClient;
