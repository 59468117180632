import { createContext } from "react";

export interface AuthorizationContextValue {
    loading?: boolean;
}

const AuthorizationContext = createContext<AuthorizationContextValue | null>(
    null,
);

export default AuthorizationContext;
