import { type ComponentProps } from "react";
import avatarUrl from "@/lib/avatarUrl";
import formatFullName from "@/lib/formatFullName";
import MuiAvatar from "@mui/material/Avatar";

const Avatar = ({
    givenName = "",
    familyName = "",
    avatar,
}: {
    givenName?: string;
    familyName?: string;
    avatar?: ComponentProps<typeof MuiAvatar>;
}) => (
    <MuiAvatar
        alt={avatar?.alt ?? formatFullName(givenName, familyName)}
        src={avatar?.src ?? avatarUrl(givenName, familyName)}
    />
);

export default Avatar;
