import { createTheme } from "@mui/material/styles";
import { nlNL } from "@mui/material/locale";

export const theme = createTheme(
    {
        components: {
            MuiButton: {
                defaultProps: {
                    variant: "contained",
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: "outlined",
                    size: "small",
                },
            },
            MuiFormControl: {
                defaultProps: {
                    variant: "outlined",
                    size: "small",
                },
            },
        },
    },
    nlNL,
);
