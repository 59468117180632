import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
} from "@mui/material";
import React from "react";
import type { DialogType } from "../../Dialog.hook";
import CloseIcon from "@mui/icons-material/Close";

export const Dialogs = ({
    handleClose,
    index,
    dialog,
}: {
    dialog: DialogType;
    index: number;
    handleClose: (index: number) => void;
}) => {
    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            data-testid="dialog"
            key={index}
            onClose={() => handleClose(index)}
            aria-labelledby="customized-dialog-title"
            open={true}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {dialog.title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => handleClose(index)}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>{dialog.children}</DialogContent>
            <DialogActions>
                {dialog.actions?.map((group, groupIndex) => (
                    <React.Fragment key={`${groupIndex}`}>
                        {groupIndex > 0 && (
                            <Divider
                                sx={{ mx: 2 }}
                                orientation="vertical"
                                variant="middle"
                                flexItem
                            />
                        )}
                        {group.map((button, buttonIndex) => (
                            <Button
                                variant="text"
                                key={`${groupIndex}_${buttonIndex}`}
                                {...button}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </DialogActions>
        </Dialog>
    );
};

export default Dialogs;
