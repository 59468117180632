import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { DRAWER_BREAKPOINT, DRAWER_WIDTH } from "@/lib/general";
import noop from "@/lib/noop";
import AccountMenu from "../AccountMenu/AccountMenu";
import TenantSelect from "../TenantSelect/TenantSelect";
import ModeToggle from "@/components/organisms/ModeToggle/ModeToggle";

const MainAppBar = ({
    handleDrawerToggle = noop,
}: {
    handleDrawerToggle: () => any;
}) => {
    return (
        <AppBar
            variant="outlined"
            elevation={0}
            position="fixed"
            sx={{
                width: {
                    [DRAWER_BREAKPOINT]: `calc(100% - ${DRAWER_WIDTH}px)`,
                },
                ml: { [DRAWER_BREAKPOINT]: `${DRAWER_WIDTH}px` },
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { [DRAWER_BREAKPOINT]: "none" } }}
                >
                    <MenuIcon />
                </IconButton>

                <ModeToggle />

                <Box sx={{ flexGrow: 1 }} />

                <TenantSelect />
                <AccountMenu />
            </Toolbar>
        </AppBar>
    );
};

export default MainAppBar;
