import { useContext } from "react";
import KeycloakContext from "./KeycloakContext";

function useKeycloak() {
    const keycloak = useContext(KeycloakContext);

    if (!keycloak) {
        throw new Error("useKeycloak must be used within a KeycloakProvider");
    }

    return keycloak;
}

export default useKeycloak;
