import { createContext, useContext, type ReactNode } from "react";
import { useDialogHook } from "./Dialog.hook";
import type { DialogContextType } from "./Dialog.types";
import Dialog from "./components/Dialog/Dialog";

export const ClientContext = createContext<DialogContextType>({} as any);

export const DialogProvider = ({ children }: { children: ReactNode }) => {
    const dialogHook = useDialogHook();

    const handleClose = (index: number) => {
        dialogHook.close(index);
    };

    return (
        <ClientContext.Provider value={dialogHook}>
            {children}

            {dialogHook.dialogs.map((dialog, index) => (
                <Dialog
                    key={index}
                    index={index}
                    handleClose={handleClose}
                    dialog={dialog}
                />
            ))}
        </ClientContext.Provider>
    );
};

export const useDialog = () => {
    return useContext(ClientContext);
};
