export const OWNUSER_UPDATE = "cms:ownuser:update" as const;
export const USER_CREATE = "cms:users:create" as const;
export const USER_READ = "cms:users:read" as const;
export const USER_UPDATE = "cms:users:update" as const;
export const USER_DELETE = "cms:users:delete" as const;
export const TENANTS_READ = "cms:tenants:read" as const;
export const TENANTTOKENS_CREATE = "cms:tenanttokens:create" as const;
export const ROLES_READ = "cms:roles:read" as const;
export const ROLES_CREATE = "cms:roles:create" as const;
export const ROLES_UPDATE = "cms:roles:update" as const;
export const ROLES_DELETE = "cms:roles:delete" as const;
export const EDUCATIONAL_PROGRAMS_READ =
    "cms:educationalprograms:read" as const;
export const EDUCATIONAL_PROGRAMS_CREATE =
    "cms:educationalprograms:create" as const;
export const EDUCATIONAL_PROGRAMS_UPDATE =
    "cms:educationalprograms:update" as const;
export const EDUCATIONAL_PROGRAMS_DELETE =
    "cms:educationalprograms:delete" as const;
export const PRODUCT_READ = "cms:products:read" as const;
export const PRODUCT_CREATE = "cms:products:create" as const;
export const PRODUCT_UPDATE = "cms:products:update" as const;
export const PRODUCT_DELETE = "cms:products:delete" as const;
export const GROUP_READ = "cms:groups:read" as const;
export const GROUP_CREATE = "cms:groups:create" as const;
export const GROUP_UPDATE = "cms:groups:update" as const;
export const GROUP_DELETE = "cms:groups:delete" as const;
export const EDUCATIONAL_PATH_READ = "cms:educationalpaths:read" as const;
export const EDUCATIONAL_PATH_CREATE = "cms:educationalpaths:create" as const;
export const EDUCATIONAL_PATH_UPDATE = "cms:educationalpaths:update" as const;
export const EDUCATIONAL_PATH_DELETE = "cms:educationalpaths:delete" as const;
export const MODULES_READ = "cms:modules:read" as const;
export const MODULES_CREATE = "cms:modules:create" as const;
export const MODULES_UPDATE = "cms:modules:update" as const;
export const MODULES_DELETE = "cms:modules:delete" as const;
export const DIDACTIC_TOOLS_READ = "cms:didactictools:read" as const;
export const DIDACTIC_TOOLS_CREATE = "cms:didactictools:create" as const;
export const DIDACTIC_TOOLS_UPDATE = "cms:didactictools:update" as const;
export const DIDACTIC_TOOLS_DELETE = "cms:didactictools:delete" as const;
export const REFERENCE_CARDS_CREATE = "cms:referencecards:create" as const;
export const REFERENCE_CARDS_READ = "cms:referencecards:read" as const;
export const REFERENCE_CARDS_UPDATE = "cms:referencecards:update" as const;
export const REFERENCE_CARDS_DELETE = "cms:referencecards:delete" as const;
export const SHORTCODE_CREATE = "cms:shortcodes:create" as const;
export const SHORTCODE_READ = "cms:shortcodes:read" as const;
export const SHORTCODE_UPDATE = "cms:shortcodes:update" as const;
export const SHORTCODE_DELETE = "cms:shortcodes:delete" as const;
export const TENANTS_UPDATE = "cms:tenants:update" as const;
